import { publicClient, privateClient } from '../../configs/axiosConfig';
import operatorEndpoints from './operatorEndpoints';

const operatorApi = {
  getOperatorInfo: async ({ operator }) => {
    try {
      const response = await publicClient.post(operatorEndpoints.getOperatorInfo, { operator });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Get operator failed");
    }
  },
  getListOperator: async (id) => {
    try {
      const response = await privateClient.post(operatorEndpoints.getListOperator, {userFrom: id});
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Get operator failed");
    }
  }
};

export default operatorApi;