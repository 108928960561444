import axios, { AxiosRequestHeaders } from "axios";

export const publicClient = axios.create({
  baseURL: "https://lokalai-be-mar24.vercel.app/api",
  headers: {
    "Content-Type": "application/json",
  },
});

export const privateClient = axios.create({
  baseURL: "https://lokalai-be-mar24.vercel.app/api",
  headers: {
    "Content-Type": "application/json",
  },
});

// Thêm interceptor để thêm token vào privateClient
privateClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders;
    }
    (config.headers as AxiosRequestHeaders).Authorization = `Bearer ${token}`;
  }
  return config;
});
