import React, { FC, useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import operatorApi from "api/operator/operator.api";
import menuApi from "api/menu/menu.api";
import Loading from "components/Loading/Loading";
import { FaDownload, FaPrint } from "react-icons/fa";

export interface MenuItem {
  id: string;
  title: string;
  price: number;
  desc: string;
  category: string;
  operator: string;
}

export interface MenuProps {
  className?: string;
}

interface Operator {
  _id: string;
  name?: string;
  photo?: string;
}

interface MenuCategory {
  _id: string;
  name: string;
  image: string;
  operator: string;
}

const Menu: FC<MenuProps> = ({ className = "" }) => {
  const { id } = useParams<{ id: string }>();
  const [operator, setOperator] = useState<Operator[]>([]);
  const [menuCategories, setMenuCategories] = useState<MenuCategory[]>([]);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const qrRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([getOperator(), getMenuCategories(), getMenuItems()]);
      setIsLoading(false);
    };

    fetchData();
  }, [id]);

  const getOperator = async () => {
    try {
      const response = await operatorApi.getOperatorInfo({ operator: id });
      if (response.success && response.operator) {
        const items: Operator[] = response.operator.map((item: any) => ({
          _id: item._id,
          name: item.name,
          photo: item.photo,
        }));
        setOperator(items);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getMenuCategories = async () => {
    try {
      const response = await menuApi.getMenuCategory({ operator: id });
      if (response.success && response.category) {
        setMenuCategories(response.category);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const getMenuItems = async () => {
    try {
      const response = await menuApi.getMenuItems({ operator: id });
      if (response.success && response.menuItem) {
        setMenuItems(response.menuItem);
      }
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  const downloadQRCode = () => {
    if (qrRef.current) {
      const svg = qrRef.current.querySelector("svg");
      if (svg) {
        const svgData = new XMLSerializer().serializeToString(svg);
        const blob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "qrcode.svg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const printQRCode = () => {
    window.print();
  };

  const renderMenuItems = (items: MenuItem[]) => (
    <div className="space-y-6"> {/* Increased spacing for better alignment */}
      {items.map((item) => (
        <div key={item.id} className="flex justify-between py-2 border-b">
          <div>
            <p className="font-semibold">{item.title}</p>
            <p className="text-xs text-gray-600">{item.desc}</p>
          </div>
          <p className="font-semibold">${item.price}</p>
        </div>
      ))}
    </div>
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="h-full w-full">
      <div className="flex justify-center w-full mt-5">
        <div className="flex items-center max-w-6xl justify-between mb-8 px-4 w-3/5 border-b-2 border-gray-400 pb-6">
          <div className="flex flex-col items-center">
            {operator[0]?.photo && (
              <img
                src={operator[0]?.photo}
                alt="Operator Logo"
                className="w-32 h-32 rounded-full mb-4"
              />
            )}
            <p className="text-sm text-gray-600">{operator[0]?.name}</p>
          </div>
          <div className="hidden md:flex items-center gap-4">
            <div className="flex flex-col">
              <button
                onClick={downloadQRCode}
                className="mt-4 bg-gray-200 px-4 py-2 rounded flex items-center gap-2"
              >
                <FaDownload /> Download
              </button>
              <button
                onClick={printQRCode}
                className="mt-2 bg-gray-200 px-4 py-2 rounded flex items-center gap-2"
              >
                <FaPrint /> Print
              </button>
            </div>
            <div ref={qrRef}>
              <QRCode value={window.location.href} size={120} />
            </div>
          </div>
        </div>
      </div>
  
      <div className="flex justify-center">
        <div className={`w-4/5 md:w-3/5 max-w-6xl py-8 px-4 ${className}`}>
          {menuCategories.map((category) => {
            const items = menuItems.filter((item) => item.category === category._id);
            const leftItemsCount = Math.ceil(items.length * 0.6);
            const leftItems = items.slice(0, leftItemsCount);
            const rightItems = items.slice(leftItemsCount);
  
            return (
              <div
                key={category._id}
                className="mb-12 pb-8 border-b-2 border-gray-400 flex flex-col md:flex-row"
              >
                {/* Left Column */}
                <div className="w-full md:w-1/2 pr-4 flex flex-col">
                  <h2 className="text-4xl font-bold mb-6 text-center md:text-left">
                    {category.name}
                  </h2>
  
                  {/* Hình ảnh dưới tiêu đề chỉ khi ở mobile */}
                  <div className="block md:hidden mb-6">
                    {category.image && (
                      <img
                        src={category.image}
                        alt={category.name}
                        className="w-full h-[250px] object-cover rounded-md mx-auto"
                      />
                    )}
                  </div>
  
                  <div className="flex-1">{renderMenuItems(leftItems)}</div>
                </div>
  
                {/* Right Column */}
                <div className="w-full md:w-1/2 flex flex-col items-center mt-6 md:mt-0">
                  {/* Hình ảnh trong cột bên phải chỉ khi ở desktop */}
                  <div className="hidden md:block mb-6">
                    {category.image && (
                      <img
                        src={category.image}
                        alt={category.name}
                        className="w-full h-[250px] object-cover rounded-md mx-auto"
                      />
                    )}
                  </div>
                  <div className="flex-1 w-full">{renderMenuItems(rightItems)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Menu;
