import React from "react";
import RoyaltyCardFront from "./RoyaltyCardFront";
import RoyaltyCardBack from "./RoyaltyCardBack";

interface RoyaltyCardProps {
  storeName: string;
  description: string;
  stamps: number;
  maxStamps: number;
  stampImage: string;
  cardColor: string;
  rewardDetails: string;
  terms: string;
  contactInfo: string;
}

const RoyaltyCard: React.FC<RoyaltyCardProps> = ({
  storeName,
  description,
  stamps,
  maxStamps,
  stampImage,
  cardColor,
  rewardDetails,
  terms,
  contactInfo,
}) => {
  return (
    <div className="flex flex-col gap-6 justify-center items-start">
      {/* Mặt trước của thẻ */}
      <RoyaltyCardFront
        storeName={storeName}
        description={description}
        stamps={stamps}
        maxStamps={maxStamps}
        stampImage={stampImage}
        cardColor={cardColor}
      />

      {/* Mặt sau của thẻ */}
      <RoyaltyCardBack
        rewardDetails={rewardDetails}
        terms={terms}
        contactInfo={contactInfo}
        cardColor={cardColor}
      />
    </div>
  );
};

export default RoyaltyCard;
