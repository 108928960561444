import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import userApi from "api/user/user.api";
import Loading from "components/Loading/Loading";
import RoyaltyCard from "./RoyaltyCard";
import { RootState } from "../../redux/store";
import operatorApi from "api/operator/operator.api";
import LoginModal from "../../containers/PageLogin/LoginModal";

interface CardDesign {
  _id: string;
  writer?: string;
  CardColor?: string;
  StampColor?: string;
  unStampColor?: string;
  NoOfStamp: number;
  PickImg?: string;
  cardDesc1?: string;
  cardDesc2?: string;
  cardDesc3?: string;
  cardDesc4?: string;
  cardDesc5?: string;
  NoofStamps: number;
}

interface Operator {
  _id: string;
  name?: string;
  email?: string;
  password?: string;
  isAdmin?: boolean;
  coverPhoto?: string;
  photo?: string;
  phone?: string;
  address?: string;
  bio?: string;
  quote?: string;
  author?: string;
  __v?: number;
  createdAt?: string;
  updatedAt?: string;
}

const Royalty: React.FC = () => {
  const [cardDesign, setCardDesign] = useState<CardDesign[]>([]);
  const [operators, setOperators] = useState<Operator[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state: RootState) => state.auth.user);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  // Fetch Royalty Cards
  const getRoyaltyCard = async () => {
    try {
      const response = await userApi.getListCardDesign(user?._id);
      if (response.success && response.stamps) {
        setCardDesign(response.stamps);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch Operators
  const getOperatorList = async () => {
    try {
      const response = await operatorApi.getListOperator(user?._id);
      if (response.success && response.operators) {
        setOperators(response.operators);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Check if user is logged in and fetch data
  useEffect(() => {
    if (!user) {
      setIsLoading(false);
      setIsLoginModalOpen(true); // Show login modal if user is not authenticated
    } else {
      getRoyaltyCard();
      getOperatorList();
    }
  }, [user]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col items-center justify-center gap-4 bg-gray-50">
      {/* Banner */}
      <div className="h-[200px] w-full bg-gray-800 relative">
        <img
          src="https://inkythuatso.com/uploads/thumbnails/800/2022/04/top-50-hanh-anh-hanh-na-n-mau-trang-aap-nhayt-14-04-16-13-21.jpg"
          alt="Banner"
          className="h-full w-full object-cover"
        />
      </div>

      {/* User Info */}
      <div className="relative flex flex-col items-center -mt-16">
        <img
          src={
            user?.photo ||
            "https://img.myloview.com/stickers/default-avatar-profile-icon-vector-social-media-user-photo-700-205577532.jpg"
          }
          alt="Avatar"
          className="h-[100px] w-[100px] rounded-full border-4 border-white object-cover"
        />
        <div className="text-center mt-4">
          <p className="font-semibold text-xl text-gray-800">
            {user?.displayName || "Người dùng mới"}
          </p>
        </div>
      </div>

      {/* User Details */}
      <div className="flex flex-col justify-center mt-4">
        <div className="text-center">
          <p className="text-gray-500">
            <b>Address:</b> {user?.username}
          </p>
        </div>
        <div className="text-center">
          <p className="text-gray-500">
            <b>Email:</b> {user?.username}
          </p>
        </div>
        <div className="text-center">
          <p className="text-gray-500">
            <b>Phone:</b> {user?.phone}
          </p>
        </div>
        <div className="text-center">
          <p className="text-gray-500">
            <b>Introduction:</b> {user?.bio}
          </p>
        </div>
      </div>

      {/* Operators List */}
      <h1 className="text-3xl font-bold text-left mb-6 mt-10">
        LIST BUSINESS REGISTER ROYALTY PROGRAMS
      </h1>
      <div className="border-b-2 border-red-500 w-1/2 mx-auto mb-6"></div>
      <div className="flex flex-wrap justify-center gap-4 px-4">
        {operators.map((operator) => (
          <div
            key={operator._id}
            className="flex items-center bg-white p-4 rounded-lg shadow-md w-[300px]"
          >
            <img
              src={operator.photo || "/default-avatar.png"}
              alt={operator.name}
              className="w-12 h-12 rounded-full mr-4"
            />
            <div>
              <h3 className="text-lg font-semibold">{operator.name || "Unknown"}</h3>
              <p className="text-sm text-gray-600">joined Royalty Program</p>
            </div>
          </div>
        ))}
      </div>

      {/* Royalty Cards */}
      <h1 className="text-3xl font-bold text-left mb-6 mt-10">ROYALTY CARDS</h1>
      <div className="border-b-2 border-red-500 w-1/2 mx-auto mb-6"></div>
      <div className="min-h-screen w-full py-10 flex flex-wrap justify-center gap-12">
        {cardDesign.map((card) => {
          const operator = operators.find((op) => op._id === card.writer);
          return (
            <RoyaltyCard
              key={card._id}
              storeName={operator?.name || "Unknown"}
              description={card.cardDesc1 || ""}
              stamps={card.NoOfStamp}
              maxStamps={card.NoofStamps}
              stampImage={operator?.photo || card.PickImg || "/default-avatar.png"}
              cardColor={card.CardColor || "#ffffff"}
              rewardDetails={card.cardDesc4 || ""}
              terms={card.cardDesc5 || ""}
              contactInfo={card.cardDesc3 || ""}
            />
          );
        })}

        {/* Show Login Modal if user is not authenticated */}
        <LoginModal
          isOpen={isLoginModalOpen}
          closeModal={() => setIsLoginModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default Royalty;
